<template>
  <v-dialog v-model="showDialog" transition="scale-transition" max-width="400">
    <v-card class="rounded-xl elevation-12">
      <v-card-title class="pa-3 title-modal text-center justify-center" color="#8e84c0">
        DETALLES DEL MÉTODO DE PAGO
      </v-card-title>
      <v-card-text class="pa-6 mb-1">
        <v-list class="pa-0">


          <template v-if="withdrawalMethod === 'bank'">
            <v-list-item-group v-for="( key, index) in orderedKeys" :key="key">
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <v-icon color="#8e84c0" size="26">{{ getIcon(key) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium text-left text-titulos">
                    {{ getLabel(key) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">
                    {{ paymentDetails[key] }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider  v-if="index < orderedKeys.length - 1" class="color-divider" />
            </v-list-item-group>
          </template>


          <template v-else-if="withdrawalMethod === 'crypto'">
            <v-list-item class="px-0">
              <v-list-item-icon>
                <v-icon color="#8e84c0">mdi-wallet</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class=" font-weight-medium text-left text-titulos">
                  DIRECCIÓN DE WALLET (RED TRC20)
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">
                  {{ paymentDetails.wallet_address }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else-if="withdrawalMethod === 'cosmo'">
            <v-list-item class="px-0">
              <v-list-item-icon>
                <v-icon color="#8e84c0">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium text-left text-titulos">
                  ID de Cosmo
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">
                  {{ paymentDetails.cosmo_id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else-if="withdrawalMethod === 'paypal'">
            <v-list-item class="px-0">
              <v-list-item-icon>
                <v-icon color="#8e84c0">mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium text-left text-titulos">
                  CORREO ELECTRÓNICO DE PAYPAL
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-2 mt-1 text-left">

                  {{ paymentDetails.email }}

                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-2">
        <v-spacer></v-spacer>
        <v-btn color="#8e84c0" text @click="showDialog = false" class="px-6 button-close">
          CERRAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      orderedKeys: [
        'country',
        'type_document',
        'document_number',
        'name',
        'last_name',
        'type_account',
        'bank',
        'account_number',
      ],
    };
  },
  props: {
    dialogDetails: {
      type: Boolean,
      required: true,
    },
    withdrawalMethod: {
      type: String,
      default: null,
      validator: (value) => value === null || ["bank", "crypto", "cosmo", "paypal"].includes(value),
    },
    paymentDetails: {
      type: Object,
      required: true,
    },
  },

  computed: {
    showDialog: {
      get() {
        return this.dialogDetails;
      },
      set(value) {
        this.$emit("update:dialogDetails", value);
      },
    },
  },


  methods: {
    getIcon(key) {
      const icons = {
        country: "mdi-flag",
        type_document: "mdi-card-account-details",
        document_number: "mdi-identifier",
        type_account: "mdi-piggy-bank",
        bank: "mdi-bank",
        account_number: "mdi-numeric",
        name: "mdi-account",
        last_name: "mdi-account",
      };
      return icons[key] || "mdi-information";
    },

    getLabel(key) {
      const labels = {
        country: "País",
        type_document: "Tipo de documento",
        document_number: "Número de documento",
        name: "Nombre del titular",
        last_name: "Apellido del titular",
        type_account: "Tipo de cuenta",
        bank: "Banco",
        account_number: "Número de cuenta",
      };
      return (labels[key] || key).toUpperCase();
    },
  },
};
</script>

<style>
.title {
  font-size: 22px !important;
  color: #8061c2;
}

.desc {
  color: #737373;
  font-size: 15px;
}

.title-modal {
  background-color: #8e84c0;
  color: white;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.text-titulos {
  color: #8e84c0;
  font-size: 13px !important;
  font-weight: 700 !important;
}

.button-close {
  font-weight: 700 !important;
}
</style>
